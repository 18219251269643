import { createSlice } from '@reduxjs/toolkit';
// utils
import { dealerJobsApi } from '../../utils/api';

// ----------------------------------------------------------------------

const initialState = {
  page: 1,
  isLoading: false,
  error: false,
  mails: {
    currentPage: null,
    previousPage: null,
    nextPage: null,
    totalPages: null,
    totalItens: null,
    rows: []
  },
  mail: null,
  labels: [
    { id: 'all', type: 'system', name: 'Todas as mensagens', unreadCount: 0 },
    { id: 'trash', type: 'system', name: 'Lixeira', unreadCount: 0 },
    { id: 'important', type: 'system', name: 'Importantes', unreadCount: 0 },
    { id: 'starred', type: 'system', name: 'Favoritos', unreadCount: 0 }
  ]
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    setPage(state, action) {
      state.page = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      state.isLoading = false;
      state.mails = action.payload;
    },

    getMailSuccess(state, action) {
      state.isLoading = false;
      state.mail = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

function getFilter(systemLabel) {
  switch (systemLabel) {
    case 'trash':
      return '&isDeleted=true';
    case 'important':
      return '&isImportant=true';
    case 'starred':
      return '&isFavorite=true';
    default:
      return null;
  }
}

export function getMails(userId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await dealerJobsApi.get(
        `/messages/author/${userId}?isPagination=1&limit=6${
          !params.typeUser || params.typeUser === '1' ? '&typeUser=1' : '&typeUser=2'
        }${!params.searchKey ? '' : `&key=${params.searchKey}`}${!params.page ? '' : `&page=${params.page}`}${
          getFilter(params?.systemLabel) ?? ''
        }`
      );
      dispatch(slice.actions.getMailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateMails(userId, params, mail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await dealerJobsApi.put(`/messages/${mail.id}`, { ...mail });
      const response = await dealerJobsApi.get(
        `/messages/author/${userId}?isPagination=1&limit=6${
          // eslint-disable-next-line eqeqeq
          !params.typeUser || params.typeUser == 1 ? '&typeUser=1' : '&typeUser=2'
        }${!params.searchKey ? '' : `&key=${params.searchKey}`}${!params.page ? '' : `&page=${params.page}`}${
          getFilter(params?.systemLabel) ?? ''
        }`
      );
      dispatch(slice.actions.getMailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateMailsGroup(userId, params, mail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await dealerJobsApi.put(`/messages/updateGroup`, { ...mail });
      const response = await dealerJobsApi.get(
        `/messages/author/${userId}?isPagination=1&limit=6${
          // eslint-disable-next-line eqeqeq
          !params.typeUser || params.typeUser == 1 ? '&typeUser=1' : '&typeUser=2'
        }${!params.searchKey ? '' : `&key=${params.searchKey}`}${!params.page ? '' : `&page=${params.page}`}${
          getFilter(params?.systemLabel) ?? ''
        }`
      );
      dispatch(slice.actions.getMailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setPage(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setPage(value));
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await dealerJobsApi.get(`/messages/${mailId}`);
      dispatch(slice.actions.getMailSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
