import React, { useState } from 'react';
// material
import { Box, Card, Stack, Container, Typography, Button, Modal, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const [showIframe, setShowIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleCloseModal = () => {
    setShowIframe(false);
    setLoading(false);
  };

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Login | DealerJobs">
      <Modal open={showIframe} onClose={handleCloseModal}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              minWidth: '38%',
              height: '80vh',
              marginTop: '10vh',
              marginBottom: '10vh',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <CloseIcon
              style={{ cursor: 'pointer', position: 'absolute', top: '0', right: '0', zIndex: '999' }}
              onClick={handleCloseModal}
            />
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute'
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
            <iframe
              title="Formulário de contato"
              className="clickup-embed clickup-dynamic-height"
              src="https://forms.clickup.com/31075871/f/xmbgz-21943/MJKRBDFHDJR69DUCL9"
              style={{
                maxWidth: '90%',
                maxHeight: '90vh',
                width: '100%',
                height: '100%',
                background: 'transparent',
                visibility: loading ? 'hidden' : 'visible'
              }}
              onLoad={handleIframeLoad}
            />
          </div>
        </div>
      </Modal>
      <Container maxWidth="lg">
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Card>
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                height: '43.438rem'
              }}
            >
              <MHidden width="mdDown">
                <Box
                  sx={{
                    background: 'linear-gradient(135deg, #5B6DE5 0%, #F75E44 100%)',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    borderRadius: '20px 0px 0px 20px',
                    width: '31.5rem',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    style={{
                      width: '14.063rem'
                    }}
                    src="/static/illustrations/DealerJobsIcon_white.svg"
                    alt="login"
                  />
                </Box>
              </MHidden>

              <Box sx={{ width: '41rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '20rem' }}>
                  <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Box
                        component="img"
                        src="/static/illustrations/DealerJobs_login_admin.svg"
                        sx={{ height: '2.188rem', mb: '0.313rem', ml: '-0.4rem' }}
                      />
                      <Typography fontWeight="600" sx={{ color: 'text.secondary' }}>
                        Realize seu login para continuar
                      </Typography>
                    </Box>

                    {/*
                <Tooltip title={capitalCase(method)}>
                  <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
                </Tooltip>
                */}
                  </Stack>

                  {method === 'firebase' && <AuthFirebaseSocials />}

                  {method !== 'auth0' ? (
                    <LoginForm />
                  ) : (
                    <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                      Login
                    </Button>
                  )}
                </Box>
              </Box>
            </Stack>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
