import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, color, height = 40 }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;

  function getColor(color) {
    if (color === 'white') return '#FFF';
    if (color === 'dark') return PRIMARY_MAIN;
    return PRIMARY_LIGHT;
  }

  return (
    <Box sx={{ ...sx, display: 'flex', justifyContent: 'center' }}>
      <CustomSVG color={getColor(color)} height={height} />
    </Box>
  );
}

const CustomSVG = ({ color, height }) => {
  console.log(color, 'color');

  return (
    <svg
      id="New_Layer"
      data-name="New Layer"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4305.39 765.39"
      style={{ fill: color, height: `${height}px` }}
    >
      <defs>
        <style>
          {`
          .cls-3 {
            font-family: Sansation-Regular, Sansation;
            font-size: 369.21px;
            letter-spacing: .7em;
          }
        `}
        </style>
      </defs>
      <path
        className="cls-1"
        style={{
          fill: color
        }}
        d="M87.46,65.23c0,2,10.64,25.94,23.28,53.2,27.27,56.53,67.17,96.43,114.39,112.39,23.28,7.98,57.86,10.64,138.33,10.64,97.76,0,109.73,1.33,136.34,14.63,75.82,38.57,81.14,178.23,7.98,220.13-13.97,8.65-35.91,12.64-66.51,13.3l-44.56,1.33-2-78.48-2-77.81h-178.9v46.55h139.66v152.96h68.5c51.21,0,74.49-3.33,94.44-12.64,73.16-33.25,107.74-130.35,77.15-218.8-6.65-18.62-10.64-34.58-9.31-34.58,7.98,0,54.53,27.93,69.83,41.9,31.26,29.26,40.57,55.2,37.24,105.08-5.99,79.14-49.21,148.31-116.38,183.55-51.87,27.93-98.43,35.25-219.47,35.25h-101.75v-206.17H87.46v46.55h126.36v208.16l144.98-3.99c189.54-4.66,234.76-17.96,302.6-90.45,55.2-59.19,77.81-123.03,77.81-219.47-.67-74.49-7.32-108.4-31.92-158.95-25.27-51.87-71.83-97.76-125.69-123.03l-45.22-21.28-224.12-2c-123.7-1.33-224.79-.67-224.79,2ZM497.13,109.12c89.12,15.96,150.97,66.51,181.56,146.98l6.65,17.96-31.26-19.95c-63.85-40.57-160.94-59.19-310.58-59.19-69.17,0-91.78-2.66-113.06-12.64-25.94-11.31-76.48-60.52-76.48-73.82,0-9.31,292.62-8.65,343.17.67Z"
      />
      <text className="cls-3" transform="translate(2567.74 684.63)">
        <tspan x="0" y="0">
          JOBS
        </tspan>
      </text>
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M808.62,684.63v-251.07h285.4v61.29h-209.71v38.4h152.85v51.69h-152.85v38.4h215.25v61.29h-290.94Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M1120.6,684.63v-1.85l119.99-249.22h109.66l120.73,249.22v1.85h-82.33l-92.67-199.74h-1.85l-93.04,199.74h-80.49ZM1195.92,635.16l33.6-55.75h133.66l33.97,55.75h-201.22Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M1510.85,684.63v-251.07h75.69v186.82h177.59v64.24h-253.28Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M1811.39,684.63v-251.07h285.4v61.29h-209.71v38.4h152.85v51.69h-152.85v38.4h215.25v61.29h-290.94Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M2402.63,598.73l46.19-35.05-.45-80.11-40.28-50.86-242.58-.45v252.03l71.56.9v-81.01h85.06l67.51,80.56h79.21l-66.21-86.01ZM2398.73,517.11c0,20.53-16.65,37.18-37.18,37.18h-126.78v-75.14h126.78c20.53,0,37.18,16.65,37.18,37.18v.78Z"
      />
    </svg>
  );
};
